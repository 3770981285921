import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import Storage from 'util/store/Storage'
const obtenerDominioCompleto = () => {
  let Str = ""
  let dominio_completo = window.location.href
  dominio_completo = dominio_completo.replace("https://", "").replace("http://", "").replace(":3000", "").replace("//", "/")
  const split = dominio_completo.split("/")
  if (split[1] === "app") {
    Str = ""
  } else {
    if (split.length > 1 && split[1] !== "app") {
      Str = "/" + split[1]
    }
  }
  return Str
}


const SideBarContent = () => {
  const { usuario } = Storage.getState()
  const path = obtenerDominioCompleto()
  const modulos_hijos = (id) => {
    const mod_hijos = []
    usuario.modulos.forEach((mod, k) => {
      if (mod.modulos_id === id) {
        mod_hijos.push({
          name: mod.descripcion,
          type: 'item',
          link: path + mod.link
        })
      }
    })
    return (mod_hijos)
  }


  const modulos_padres = () => {
    const mod_padres = [
      {
        name: 'DASHBOARD',
        type: 'item',
        icon: 'view-dashboard',
        link: path + '/app/dashboard'
      }
    ]
    usuario.modulos.forEach((mod, k) => {
      if (mod.modulos_id === null && mod.id !== 1) {
        mod_padres.push({
          name: mod.descripcion,
          icon: mod.icono,
          type: 'collapse',
          children: modulos_hijos(mod.id)
        })
      }
    })
    const all = mod_padres
    try {
      if (all != null) {
        for (let index = 0; index < all.length; index++) {
          if (all[index].type === "collapse" && all[index].children.length === 0) {
            mod_padres.splice(index, 1)
            index = index - 1
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
    return (mod_padres)
  }

  const navigationMenusbd = [
    {
      name: 'sidebar.main',
      type: 'section',
      children:
        modulos_padres()

    }
  ]


  return (
    <CustomScrollbars className="scrollbar">
      <Navigation menuItems={navigationMenusbd} />
    </CustomScrollbars>
  );
};

export default SideBarContent;
