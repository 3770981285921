import React, { useEffect, useState } from 'react'
//panel del filtro
import HeaderTitle from './Components/HeaderTitle'
import Highcharts from 'highcharts'
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import FiltrosFecha from './Components/FiltrosFecha';
import { getChartsData } from '../ChartHelpers/Charts.services';
import { IDatasetChart } from '../ChartHelpers/Charts.interface';
import GraficaTanques from 'app/vistas/Configuracion/MedicionTanques/GraficaTanques';
window.Highcharts = Highcharts;
//ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
const ChartSaldosTanques = ({ updateData, identificadorEstacion }: any): JSX.Element => {
    const [dataSet, setDataset] = useState<any[]>([])

    useEffect(() => {
        getData()
    }, [updateData])

    useEffect(() => { }, [dataSet])

    const getData = async (): Promise<void> => {
        const body = {
            identificadorEstacion: identificadorEstacion
        };
        const data = await getChartsData('SALDOS_TANQUES', body)
        setDataset(data)
        crearGraficas(data)
    }

    const crearGraficas = (data: any[]) => {
        dataSet.forEach((data: any, key) => {
            let { maximo, saldo, unidad } = data
            const color = '#000'
            Highcharts.chart(('saldo-tanque-' + key), Highcharts.merge(
                {
                    chart: {
                        type: 'solidgauge'
                    },
                    title: null,
                    pane: {
                        center: ['50%', '85%'],
                        size: '140%',
                        startAngle: -90,
                        endAngle: 90,

                        background: {
                            backgroundColor: '#fff',
                            borderColor: color,
                            setColor: color,
                            color: color,
                            innerRadius: '60%',
                            outerRadius: '100%',
                            shape: 'arc'
                        }
                    },

                    exporting: {
                        enabled: false
                    },

                    tooltip: {
                        enabled: true
                    },

                    // the value axis
                    yAxis: {
                        stops: [
                            [0.1, color], // green
                            [0.5, color], // yellow
                            [0.9, color] // red
                        ],
                        lineWidth: 0,
                        tickWidth: 0,
                        minorTickInterval: null,
                        tickAmount: 2,
                        title: {
                            y: -70
                        },
                        labels: {
                            y: 16
                        }
                    },

                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                y: 5,
                                borderWidth: 0,
                                useHTML: true
                            }
                        }
                    }
                } as any, {
                    yAxis: {
                        min: 0,
                        max: parseFloat(maximo),
                        title: {
                            text: unidad
                        }
                    },

                    credits: {
                        enabled: false
                    },

                    series: [{
                        name: unidad,
                        data: [parseFloat(saldo)],
                        color: color,
                        dataLabels: {
                            format:
                                '<div style="text-align:center">' +
                                '<span style="font-size:25px">{y}</span><br/>' +
                                '<span style="font-size:12px;opacity:0.4">' + unidad + '</span>' +
                                '</div>'
                        },

                        tooltip: {
                            valueSuffix: unidad
                        }
                    }]

                } as any));
        })
    }


    return (
        <>
            <HeaderTitle title={'INVENTARIO DE TANQUES'} />
            <div className="row">
                {
                    dataSet &&
                    dataSet.map((data, key) => {
                        return <div id={"saldo-tanque-" + key} className="col-md-4 mx-auto" key={key}></div>
                    })
                }
            </div>
        </>
    )
}
export default ChartSaldosTanques
