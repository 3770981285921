import React, { } from 'react';
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import CircularProgress from '@material-ui/core/CircularProgress';
import Storage from 'util/store/Storage'
import Servidor from 'util/Servidor'
import Axios from 'axios'
import swal from 'sweetalert'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import "./aplication.css"
const md5 = require('md5');
class SignInClass extends React.Component {
  constructor() {
    super();
    this.state = {
      txtUser: '',
      txtPass: '',
      loader: false,
      loader2: true,
      dialogOlvideContraseña: false,
      usernameForget: '',
      cedula: '',
      color1: "#3f51b5",
      color2: "#3f51b5"
    }
  }


  handleDialogOlvideContrasena = () => {
    this.setState({
      dialogOlvideContraseña: !this.state.dialogOlvideContraseña,
      cedula: '',
      usernameForget: ''
    })
  }


  handleLoader = () => {
    this.setState({
      loader: !this.state.loader
    })
  }

  handleChangeText = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  validar = () => {
    if (this.state.txtUser === "") {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: '!DEBE LLENAR EL CAMPO DE USUARIO!'
      })
      return false
    } else if (this.state.txtPass === "") {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: '!DEBE LLENAR EL CAMPO DE CLAVE!'
      })
      return false
    } else {
      return true
    }
  }

  actualizarEstado(token, userall) {
    Storage.dispatch({
      type: "INICIO_DE_SESION",
      token,
      usuario: userall,
      obtencionInfoUsuario: true
    })
  }

  recargar = () => {
    var Str = ""
    var dCompleto = window.location.href
    dCompleto = dCompleto.replace("https://", "").replace("http://", "").replace(":3000", "").replace("//", "/")
    var dsplit = dCompleto.split("/")
    if (dsplit[1] === "app") {
      Str = "/"
    } else {
      if (dsplit.length > 1 && dsplit[1] !== "app" && dsplit[1] !== "") {
        Str = "/" + dsplit[1] + "/"
      } else {
        Str = "/"
      }
    }
    setTimeout(function () { window.location.href = Str; }, 3000)
  }

  iniciarSesion = () => {
    var validado = this.validar()
    if (validado) {
      this.handleLoader()
      const { dominio, tipo_empresa, negocio } = Storage.getState()
      var user = this.state.txtUser
      var pass = (md5(this.state.txtPass))
      var token = false
      var empresa = ""
      if (tipo_empresa === 3) {
        empresa = negocio
      } else {
        empresa = dominio
      }
      var datos = {
        "user": user.toUpperCase(),
        "pass": pass,
        /*"empresas_id": empresa,  veija */
        "identificadorNegocio": empresa
      }
      Axios.post(Servidor.host + Servidor.puertoApp + '/api/v1/signin/', datos)
        .then(response => {
          if (response.status === 200) {
            token = true
            localStorage.removeItem('token');
            localStorage.setItem('token', response.data.data.token)
            setTimeout(
              () => { this.actualizarEstado(token, response.data.data) },
              200
            )

          } else if (response.status === 401) {
            alert(response.data.message)
            this.handleLoader()
          } else {
            alert('!CREDENCIALES INCORRECTAS!')
            this.handleLoader()
          }
        })
        .catch((err) => {
          alert('!CREDENCIALES INCORRECTAS!')
          this.handleLoader()
        })
    }
  }

  enviarDatos = (evt) => {
    const { cedula, usernameForget } = this.state;
    evt.preventDefault();
    if (cedula.trim === '' || cedula.length === 0) {
      swal("ERROR", "LA IDENTIFICACION ES NECESARIA", "warning")
    } else if (usernameForget.trim === '' || usernameForget.length === 0) {
      swal("ERROR", "EL CAMPO USUARIO NO PUEDE ESTAR VACIO", "warning")
    } else {
      const usuario = this.state.usernameForget;
      const num_documento = this.state.cedula;
      const data = {
        "identificacion": num_documento,
        "usuario": usuario,
        "dominio": Storage.getState().dominio
      };
      const headers = {
        Authorization: Servidor.obtenerToken(),
        versionapp: Servidor.versionApp,
        versioncode: Servidor.versionCode,
        fecha: moment().format(),
        aplicacion: Servidor.aplicacion,
        identificadordispositivo: Servidor.aplicacion
      }
      Axios.put(Servidor.host + Servidor.puertoCentury + '/api/persona/forgetPassword', data, { headers: headers })
        .then((response) => {
          var mensaje = response.data.mensaje;
          if (response.status === 200) {
            if (response.data.success) {
              swal({
                title: mensaje,
                icon: "success",
                closeOnClickOutside: false,
                buttons: {
                  aceptar: {
                    text: "OK",
                    value: "continuar"
                  }
                },
              }).then((value) => {
                switch (value) {
                  case "continuar":
                    Storage.dispatch({
                      type: "OLVIDO_CONTRASEÑA",
                      ventanaOlvideContra: false
                    })
                    this.handleDialogOlvideContrasena()
                    break;

                  default:
                }
              });
            } else {
              swal("ERROR", mensaje, "warning")
            }
          }
        })
    }
  }

  keyBuscar = (e) => {
    if (e.key === 'Enter') {
      this.iniciarSesion()
    }
  }


  render() {
    var { txtUser, txtPass, loader, cedula, usernameForget, dialogOlvideContraseña/* , color1, color2 */ } = this.state;
    var { dominio, negocio, loaderDominio, color1, color2 } = Storage.getState();
    return (

      loaderDominio
        ?
        <div style={{ width: '100%' }} >
          <center style={{
            display: 'flex',
            height: '100vh'

          }}>
            <CircularProgress className="mx-auto" style={{ margin: 'auto' }} size={100} />
          </center>
        </div >
        :
        dominio === 0 ?
          <div

            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="col-md-12 hidden-sm hidden-xs-down hidden-sm-down"
              style={{ padding: 0, width: '100%', height: '100%' }}
            >
              <img style={{ width: '100%', height: '100%' }} draggable="false" src={Servidor.hostMultimedia + "/pagenotfound.svg"} alt="login"></img>
            </div>
          </div>
          : <div

            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="col-md-8 hidden-sm hidden-xs-down hidden-sm-down"
              style={window.screen.width > 720 ? { padding: 0, width: '100%', height: '100%' } : { display: 'none' }}
            >
              <img style={{ width: '100%', height: '100%' }} src={Servidor.hostMultimedia + dominio + "/pagina/loginizq.jpg"} alt="login" draggable="false"></img>
            </div>
            <div className="col-md-4 mx-auto"
              style={{
                height: '100%',
                backgroundImage: `url(${Servidor.hostMultimedia}${dominio}/pagina/loginder.png)`,
                backgroundSize: 'cover',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div className="app-login-main-content mx-auto " style={{ boxShadow: "8px 8px 8px 8px rgba(0,0,0,0.5)" }}>

                <div className="app-logo-content d-flex derecha mx-auto" style={{ backgroundColor: color1 }}>
                  <Link className="logo-lg" to="#" title="Jambo">
                    <img src={Servidor.hostMultimedia + negocio + "/pagina/logo_login_177_65.png"} alt="Negocio" title="Negocio" draggable="false"/>
                  </Link>

                </div>

                <div className="app-login-content ">
                  <div className="app-login-form">
                    <form>
                      <fieldset>
                        <TextField
                          label={"USUARIO"}
                          fullWidth
                          onChange={this.handleChangeText('txtUser')}
                          defaultValue={txtUser}
                          margin="normal"
                          className="mt-1 my-sm-3"
                        />
                        <TextField
                          type="password"
                          label={"CLAVE"}
                          fullWidth
                          onChange={this.handleChangeText('txtPass')}
                          defaultValue={txtPass}
                          margin="normal"
                          className="mt-1 my-sm-3"
                          onKeyDown={this.keyBuscar}
                        />

                        <div className="mb-3 d-flex align-items-center justify-content-between">

                          <Button onClick={() => this.iniciarSesion()} variant="contained" style={color2 === "#000000" ? { backgroundColor: color2, color: "#ffffff" } : { backgroundColor: color2 }}>
                            INGRESAR
                          </Button>

                          <Link to="#" onClick={this.handleDialogOlvideContrasena.bind(this)} style={{ color: color2 }}>
                            OLVIDE MI CONTRASEÑA
                          </Link>

                          <Dialog
                            open={dialogOlvideContraseña}
                            onClose={() => this.handleDialogOlvideContrasena()}
                            aria-labelledby="form-dialog-title"
                            maxWidth={'md'}
                            fullWidth={true}
                            style={{ padding: '0', zIndex: 500 }}
                            disableBackdropClick={false}>
                            <DialogContent style={{ padding: '30px', zIndex: 500 }}>
                              <div className="row">
                                <h3>OLVIDE MI CONTRASEÑA</h3>
                              </div>
                              <div className="row">
                                <div className={"col-md-12"}>
                                  <label htmlFor="identificacion" className="col-form-label">IDENTIFICACIÓN</label>
                                  <input id="numordencompra" placeholder="Escriba su numero de idenificacion" autoComplete="off" className="form-control" onChange={this.handleChangeText('cedula')} type="text" value={cedula} />
                                </div>
                              </div>
                              <div className="row">
                                <div className={"col-md-12"}>
                                  <label htmlFor="identificacion" className="col-form-label">USUARIO</label>
                                  <input id="usuario" placeholder="Escriba su usuario" autoComplete="off" className="form-control" onChange={this.handleChangeText('usernameForget')} type="text" value={usernameForget} />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: '10px' }}>
                                <button className="btn btn-success col-md-3" onClick={this.enviarDatos}>
                                  ENVIAR
                                </button>
                                <button className="btn btn-danger col-md-3" onClick={this.handleDialogOlvideContrasena}>
                                  CANCELAR
                                </button>
                              </div>

                            </DialogContent>
                          </Dialog>
                        </div>

                        <div className="row" style={{ marginTop: '10px' }}>
                          <h5 style={{ color: color2 }} className="mx-auto">{"VERSION: " + Servidor.versionApp}</h5>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {
              loader &&
              <div className="loader-view">
                <CircularProgress />
              </div>
            }
          </div>


    );
  }
}


export default SignInClass;
