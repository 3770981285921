import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas
const AjusteInicial = lazy(async () => import('./AjusteInicial'))
const AnulacionVentas = lazy(async () => import('./AnulacionVentas'))
const AnulacionCargas = lazy(async () => import('./AnulacionCargas'))
const Entrada = lazy(async () => import('./Entrada'))
const Facturador = lazy(async () => import('./Facturador'))
const OrdenCompra = lazy(async () => import('./OrdenCompra'))
const PagoTerceros = lazy(async () => import('./pagoTerceros'))
const Recibo_gas = lazy(async () => import('./Recibo_gas'))
const Remarcacion = lazy(async () => import('./Remarcacion'))
const Salidas = lazy(async () => import('./Salidas'))
const Traslados = lazy(async () => import('./Traslados'))
const VentaManual = lazy(async () => import('./VentaManual'))

//const VentaManual = lazy(async () => import('./VentaManual'))

const Movimientos = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/ajusteinicial`} component={AjusteInicial} />
          <Route path={`${match.url}/anulacion-cargas`} component={AnulacionCargas} />
          <Route path={`${match.url}/anulacionventas`} component={AnulacionVentas} />
          <Route path={`${match.url}/entrada`} component={Entrada} />
          <Route path={`${match.url}/facturador`} component={Facturador} />
          <Route path={`${match.url}/ordencompra`} component={OrdenCompra} />
          <Route path={`${match.url}/pago_terceros`} component={PagoTerceros} />
          <Route path={`${match.url}/recibo_gas`} component={Recibo_gas} />
          <Route path={`${match.url}/remarcacion`} component={Remarcacion} />
          <Route path={`${match.url}/salidas`} component={Salidas} />
          <Route path={`${match.url}/traslados`} component={Traslados} />
          <Route path={`${match.url}/venta-manual`} component={VentaManual} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default Movimientos
