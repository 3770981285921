import Axios from 'axios';
import server from 'util/Server';
import { sessionCaducada, getColorCombustible } from 'util/funciones';
import moment from "moment";
import Highcharts from "highcharts";
const headers = {
    Authorization: server.obtenerToken(),
    versionapp: server.versionApp,
    versioncode: server.versionCode,
    fecha: moment().format(),
    aplicacion: server.aplicacion,
    identificadordispositivo: server.aplicacion,
};
export const getGraficaCombinada = async (data) => {
    return new Promise(function (resolve, reject) {
        Axios.post(
            server.host + server.puertoMovimiento + "/api/reportes/dashboard/combinado",
            data,
            { headers: headers }
        )
            .then((res) => {
                if (res.status === server.responseOK) {
                    const data = res.data.data
                    let islas = []
                    let islasIds = []
                    let totalIsla = []
                    let surtidores = []
                    // eslint-disable-next-line
                    let totalProductos = 0
                    let arrayTorta = []
                    if (data != null) {
                        data.cantidadIsla.forEach((det) => {
                            islas.push("ISLA" + det.isla)
                            islasIds.push(det.isla)
                            totalIsla.push(0)
                        })
                        data.dataSurtidores.forEach((surt) => {
                            if (!surtidores[(parseInt(surt.surtidor) - 1)]) {
                                surtidores[(parseInt(surt.surtidor) - 1)] = {
                                    type: "column",
                                    name: "SURTIDOR " + surt.surtidor,
                                    data: [],
                                }
                                totalIsla[islasIds.indexOf(surt.isla)] += parseFloat(surt.total_venta)
                                surtidores[(parseInt(surt.surtidor) - 1)].data[islasIds.indexOf(surt.isla)] = parseFloat(surt.total_venta)
                            } else {
                                totalIsla[islasIds.indexOf(surt.isla)] += parseFloat(surt.total_venta)
                                surtidores[(parseInt(surt.surtidor) - 1)].data[islasIds.indexOf(surt.isla)] = parseFloat(surt.total_venta)
                            }
                        })
                        data.ProductosTorta.forEach((torta) => {
                            totalProductos += parseFloat(torta.total_venta)
                        })
                        data.ProductosTorta.forEach((torta) => {
                            arrayTorta.push({
                                name: torta.descripcion,
                                y: parseFloat(torta.total_venta),
                                color: getColorCombustible((torta.descripcion + "").toUpperCase()), // Jane's color
                            })
                        })
                    }
                    surtidores.forEach((surtidor, k1) => {
                        for (let index = 0; index < surtidor.data.length; index++) {
                            if (typeof surtidor.data[index] === "undefined") {
                                surtidores[k1].data[index] = 0
                            }
                        }
                    })
                    surtidores.push({
                        type: "spline",
                        name: "TOTAL DE VENTAS POR ISLA",
                        data: totalIsla,
                        marker: {
                            lineWidth: 2,
                            lineColor: Highcharts.getOptions().colors[3],
                            fillColor: "white",
                        },
                    })
                    surtidores.push({
                        type: "pie",
                        name: "VENTAS POR PRODUCTO",
                        data: arrayTorta,
                        center: [100, 80],
                        size: 100,
                        showInLegend: false,
                        dataLabels: {
                            enabled: true,
                        },
                    })
                    resolve({
                        islas,
                        surtidores
                    })
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((error) => {
                reject(error, undefined)
            });
    })
}

export const getCreditosUltimos7Dias = async (data) => {
    return new Promise(function (resolve, reject) {
        Axios.post(
            server.host + server.puertoMovimiento + "/api/reportes/dashboard/CREDITO_ULTIMOS_7_DIAS",
            data,
            { headers: headers }
        )
            .then((res) => {
                if (res.status === server.responseOK) {
                    resolve(res.data.data)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((error) => {
                reject(error, undefined)
            });
    })
}

export const getVentasPorDias = async (data) => {
    return new Promise(function (resolve, reject) {
        Axios.post(
            server.host + server.puertoMovimiento + "/api/reportes/dashboard/VENTAS_TOP_POR_DIA",
            data,
            { headers: headers }
        )
            .then((res) => {
                if (res.status === server.responseOK) {
                    const data = {
                        categories: [],
                        series: []
                    }
                    try {
                        const datos = res.data.data
                        if (datos != null) {
                            datos.forEach((semana) => {
                                data.categories.push(moment(semana.fecha,"YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY"))
                                data.series.push(parseFloat(semana.sum))
                            })
                        }
                    } catch (error) { }
                    resolve(data)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((error) => {
                reject(error, undefined)
            });
    })
}

export const getConversionesUltimos7Dias = async (data) => {
    return new Promise(function (resolve, reject) {
        Axios.post(
            server.host + server.puertoMovimiento + "/api/reportes/dashboard/conversiones_ultimos_7_dias",
            data,
            { headers: headers }
        )
            .then((res) => {
                if (res.status === server.responseOK) {
                    resolve(res.data.data)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((error) => {
                reject(error, undefined)
            });
    })
}

export const getPromotoresMasVentasHoy = async (data) => {
    return new Promise(function (resolve, reject) {
        Axios.post(
            server.host + server.puertoMovimiento + "/api/reportes/dashboard/promotores_mas_ventas_hoy",
            data,
            { headers: headers }
        )
            .then((res) => {
                if (res.status === server.responseOK) {
                    if (res.data.data != null) {
                        resolve(res.data.data)
                    } else {
                        resolve({})
                    }

                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((error) => {
                reject(error, undefined)
            });
    })
}
