import React from "react";
import Highcharts from 'highcharts'
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import moment from "moment";
import Storage from "util/store/Storage";
import Axios from "axios";
import server from "util/Server";
import { setearMiles } from "util/funciones"
window.Highcharts = Highcharts;
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
class IslasView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topIslasSeries: [],
            topIslasCategories: [],
            checkIslas: 0,
            topSeriesTorta: [],
            isShow: true,
            loaderInterno: true,
            refreshIntervalId: 0,
        };
    }


    componentWillMount() {
        let intervalId = setInterval(() => {
            switch (this.state.checkIslas) {
                case 0:
                    this.getInfoIsla("DIA", true)
                    break;
                case 1:
                    this.getInfoIsla("SEMANA", true)
                    break;
                case 2:
                    this.getInfoIsla("MES", true)
                    break;
                case 3:
                    this.getInfoIsla("ANIO", true)
                    break;
                default:
                    this.getInfoIsla("DIA", true)
                    break;
            }
        }, 20000);

        this.setState({ refreshIntervalId: intervalId })
    }

    componentWillUnmount() {
        clearInterval(this.state.refreshIntervalId);
    }

    componentDidMount() {
        this.getInfoIsla("DIA", false)
    }

    validarUsuario = () => {
        const { empresasHijos, usuario } = Storage.getState();
        let empresa = "";
        if (usuario.tipo === 5) {
            empresa = usuario.empresas_id;
        } else {
            empresa = empresasHijos;
        }
        return empresa;
    };


    getInfoIsla = async (type, noLoader) => {
        const { negocio } = Storage.getState()
        const headers = {
            Authorization: server.obtenerToken(),
            versionapp: server.versionApp,
            versioncode: server.versionCode,
            fecha: moment().format(),
            aplicacion: server.aplicacion,
            identificadordispositivo: server.aplicacion,
        };
        let data = {}
        if (!noLoader) {
            this.setState({
                loaderInterno: true
            })
        }
        switch (type) {
            case "DIA":
                this.setState({
                    checkIslas: 0
                })
                data = {
                    fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
                    fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
                    identificadorEstacion: this.validarUsuario(),
                    identificadorNegocio: negocio
                };

                await Axios.post(
                    server.host +
                    server.puertoMovimiento +
                    "/api/reportes/dashboard/ISLAS_MAS_VENTAS_HOY",
                    data,
                    { headers: headers }
                )
                    .then((res) => {
                        if (res.data.data.categories.length !== 0) {
                            this.setVentasIsla(0,
                                {
                                    topIslasSeries: res.data.data.series,
                                    topIslasCategories: res.data.data.categories,
                                    loaderInterno: false
                                }
                            )
                        } else {
                            this.setState({
                                isShow: false,
                                checkIslas: 0,
                                loaderInterno: false
                            })
                        }
                    })
                    .catch((error) => console.log(error));
                break;
            case "MES":
                this.setState({
                    checkIslas: 2
                })
                data = {
                    fecha_inicial: moment(new Date()).format("YYYY-MM-1 00:00:00"),
                    fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
                    identificadorEstacion: this.validarUsuario(),
                    identificadorNegocio: negocio
                };

                await Axios.post(
                    server.host +
                    server.puertoMovimiento +
                    "/api/reportes/dashboard/ISLAS_MAS_VENTAS_MENSUAL",
                    data,
                    { headers: headers }
                )
                    .then((res) => {
                        if (res.data.data.categories.length !== 0) {
                            this.setVentasIsla(2,
                                {
                                    topIslasSeries: res.data.data.series,
                                    topIslasCategories: res.data.data.categories,
                                    loaderInterno: false
                                }
                            )
                        } else {
                            this.setState({
                                isShow: false,
                                checkIslas: 1,
                                loaderInterno: false
                            })
                        }
                    })
                    .catch((error) => console.log(error));
                break;
            case "SEMANA":
                this.setState({
                    checkIslas: 1
                })
                data = {
                    fecha_inicial: moment(new Date()).subtract("days", 6).format("YYYY-MM-DD 00:00:00"),
                    fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
                    identificadorEstacion: this.validarUsuario(),
                    identificadorNegocio: negocio
                };

                await Axios.post(
                    server.host +
                    server.puertoMovimiento +
                    "/api/reportes/dashboard/ISLAS_MAS_VENTAS_SEMANAL",
                    data,
                    { headers: headers }
                )
                    .then((res) => {
                        if (res.data.data.categories.length !== 0) {
                            this.setVentasIsla(1,
                                {
                                    topIslasSeries: res.data.data.series,
                                    topIslasCategories: res.data.data.categories,
                                    loaderInterno: false
                                }
                            )
                        } else {
                            this.setState({
                                isShow: false,
                                loaderInterno: false
                            })
                        }
                    })
                    .catch((error) => console.log(error));
                break;
            case "ANIO":
                this.setState({
                    checkIslas: 3
                })
                data = {
                    fecha_inicial: moment(new Date()).format("YYYY-01-1 00:00:00"),
                    fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
                    identificadorEstacion: this.validarUsuario(),
                    identificadorNegocio: negocio
                };

                await Axios.post(
                    server.host +
                    server.puertoMovimiento +
                    "/api/reportes/dashboard/ISLAS_MAS_VENTAS_ANUAL",
                    data,
                    { headers: headers }
                )
                    .then((res) => {
                        if (res.data.data.categories.length !== 0) {
                            this.setVentasIsla(3,
                                {
                                    topIslasSeries: res.data.data.series,
                                    topIslasCategories: res.data.data.categories,
                                    loaderInterno: false
                                }
                            )
                        } else {
                            this.setState({
                                isShow: false,
                                loaderInterno: false
                            })
                        }
                    })
                    .catch((error) => console.log(error));

                break;

            default:

                break;
        }

    }

    setVentasIsla = (index, data) => {
        let topIslasSeries = [];
        let topIslasCategories = [];
        switch (index) {
            case 0:
                topIslasSeries = data.topIslasSeries;
                topIslasCategories = data.topIslasCategories;
                break;
            case 3:
                topIslasSeries = data.topIslasSeries;
                topIslasCategories = data.topIslasCategories;
                break;
            default:
                topIslasSeries = data.topIslasSeries;
                topIslasCategories = data.topIslasCategories;
                break;
        }
        this.setState({
            topIslasSeries: topIslasSeries,
            topIslasCategories: topIslasCategories,
            checkIslas: index,
            isShow: true
        });
        try {
            this.renderizarGrafica(topIslasCategories, topIslasSeries)
        } catch (error) {

        }
    };

    renderizarGrafica = (topIslasCategories, topIslasSeries) => {
        Highcharts.chart("ventas-x-isla-externo", {
            chart: {
                type: "bar",
            },
            title: {
                text: "",
            },
            xAxis: {
                categories: topIslasCategories,
                title: {
                    text: null,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: "Ventas en islas",
                    align: "high",
                },
                labels: {
                    overflow: "justify",
                },
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.point.category + '</b>: $ ' + setearMiles(this.point.y);
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "top",
                x: -40,
                y: 80,
                floating: false,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
                shadow: true,
            },
            credits: {
                enabled: false,
            },
            series: topIslasSeries,
        });
    }

    render() {
        const { checkIslas, isShow } = this.state
        return (
            <>
                <div className="row">
                    <div className="container noDatosExternos mt-4">
                        <h2 className="text-center bg-primary">VENTAS POR ISLA</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="botones mx-auto text-center">
                        <button
                            className={
                                checkIslas === 0
                                    ? "btn btn-sm btn-primary"
                                    : "btn btn-sm btn-outline-dark"}
                            onClick={() => this.getInfoIsla("DIA", false)}
                        >
                            HOY
                        </button>
                        <button
                            className={
                                checkIslas === 1
                                    ? "btn btn-sm btn-primary"
                                    : "btn btn-sm btn-outline-dark"}
                            onClick={() => this.getInfoIsla("SEMANA", false)}
                        >
                            ESTA SEMANA
                        </button>
                        <button
                            className={
                                checkIslas === 2
                                    ? "btn btn-sm btn-primary"
                                    : "btn btn-sm btn-outline-dark"}
                            onClick={() => this.getInfoIsla("MES", false)}
                        >
                            ESTE MES
                        </button>
                        <button
                            className={
                                checkIslas === 3
                                    ? "btn btn-sm btn-primary"
                                    : "btn btn-sm btn-outline-dark"}
                            onClick={() => this.getInfoIsla("ANIO", false)}
                        >
                            ESTE AÑO
                        </button>
                    </div>
                </div>

                <div id="ventas-x-isla-externo" className="col-md-11 mx-auto"
                    style={isShow
                        ? { display: "block" }
                        : { display: "none" }
                    }></div>
                {isShow ? null : <div className="text-center noDatos">
                    {" "}
                    <span>NO HAY DATOS{
                        checkIslas === 0 ? " HOY"
                            : checkIslas === 1 ? " ESTA SEMANA"
                                : checkIslas === 2 ? " ESTE MES"
                                    : " ESTE AÑO"}</span>{" "}
                </div>}
            </>
        );
    }
}

export default IslasView;
