import React from "react";
import Highcharts from "highcharts";
import * as ChartModuleMore from "highcharts/highcharts-more.js";
import HCSoldGauge from "highcharts/modules/solid-gauge";
import moment from "moment";
import 'moment/locale/es'
moment.locale("es");
window.Highcharts = Highcharts;
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
class VentasMesActual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkTortas: 0,
      dia: [],
      data: [],
      isShow: true,
      mesActual: ""
    };
  }

  componentWillMount() {
    const { categories, series } = this.props;
    this.setState({
      dia: categories,
      data: series,
      mesActual: moment(new Date())
        .format("MMMM")
        .toLocaleUpperCase()
    });

  }

  async componentDidMount() {
    await this.renderizarGrafica(this.state.dia, this.state.data);
  }

  renderizarGrafica = (categories, series) => {
    Highcharts.chart("vhola", {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Total fruit consumption",
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
      },
      plotOptions: {
        column: {
          stacking: "percent",
        },
      },
      series: [
        {
          name: "VENTAS EN ($)",
          data: series,
        },
      ],
    });
  };

  render() {
    const { isShow } = this.state;
    return (<>
      <div className="row">
        <div className="container noDatos my-4">
          <h2 className="text-center h1 bg-primary">VENTA DE COMBUSTIBLE DEL MES DE  {this.state.mesActual}</h2>
        </div>
      </div>
      <div className="row">
        <div
          id="vhola"
          className="col-md-11 mx-auto"
          style={
            isShow
              ? { marginTop: "15px", display: "block" }
              : { display: "none" }
          }
        ></div>
      </div>
    </>);
  }
}

export default VentasMesActual;
