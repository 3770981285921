import { formatearFecha, formatosFecha, iterarFechas } from "util/formatDate"

export const getMonthByNumber = (monthNumber: number): string => {
    const meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE',]
    return meses[monthNumber - 1]
}

export const getArrayMonth = (months: number[]): string[] => {
    return months.map((month) => getMonthByNumber(month))
}

export const getLast7Days = (): string[] => {
    const fechaFinal = new Date()
    const fechaInicial = new Date()
    fechaInicial.setDate(fechaFinal.getDate() - 6)
    const daysArray = iterarFechas(fechaInicial, fechaFinal)
    return daysArray.map((day) => `'${formatearFecha(day, formatosFecha.fechaServidor)}'`)
}


