import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas
const GestionServicios = lazy(async () => import('./GestionServicios'))
const ProgramacionServicios = lazy(async () => import('./programacionServicios'))
const TipoServicios = lazy(async () => import('./TipoServicios'))

const Servicios = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallvack={<Loader />}>
          <Route path={`${match.url}/gestion_servicios`} component={GestionServicios} />
          <Route path={`${match.url}/programacion_servicios`} component={ProgramacionServicios} />
          <Route path={`${match.url}/tipo_servicios`} component={TipoServicios} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default Servicios
