import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas
const GestionAcumulacion = lazy(async () => import('./GestionAcumulacion'))
const GestionClientes = lazy(async () => import('./GestionClientes'))
const GestionFidelizacion = lazy(async () => import('./GestionFidelizacion'))
const GestionPremios = lazy(async () => import('./GestionPremios'))
const GestionProveedores = lazy(async () => import('./GestionProveedores'))
const GestionPuntos = lazy(async () => import('./GestionPuntos'))
const GestionRedencion = lazy(async () => import('./GestionRedencion'))
const MediosFidelizacion = lazy(async () => import('./MediosFidelizacion'))

const Fidelizacion = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/gestion_acumulacion`} component={GestionAcumulacion} />
          <Route path={`${match.url}/gestion_clientes`} component={GestionClientes} />
          <Route path={`${match.url}/gestion_fidelizacion`} component={GestionFidelizacion} />
          <Route path={`${match.url}/gestion_medios_fidelizacion`} component={MediosFidelizacion} />
          <Route path={`${match.url}/gestion_premios`} component={GestionPremios} />
          <Route path={`${match.url}/gestion_proveedores`} component={GestionProveedores} />
          <Route path={`${match.url}/gestion_puntos`} component={GestionPuntos} />
          <Route path={`${match.url}/gestion_redencion`} component={GestionRedencion} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default Fidelizacion
