import React from "react";
import AppLayouts from "./AppLayouts";
import Routes from "../../app/routes";
import { useSelector } from "react-redux";
import Storage from "util/store/Storage"
import Servidor from "util/Server"
import Axios from 'axios'

import CircularProgress from '@material-ui/core/CircularProgress';
const AppLayout = () => {

  const horizontalNavPosition = useSelector(({ settings }) => settings.horizontalNavPosition);
  const navigationStyle = useSelector(({ settings }) => settings.navigationStyle);
  const onGetLayout = (layout) => {
    switch (layout) {
      case "inside_the_header":
        return "InsideHeaderNav";

      case "above_the_header":
        return "AboveHeaderNav";

      case "below_the_header":
        return "BelowHeaderNav";
      default:
        return "Vertical";
    }
  };

  const Layout = AppLayouts[navigationStyle === "vertical_navigation" ? "Vertical" : onGetLayout(horizontalNavPosition)];
  return (
    <Layout>
      <Routes />
    </Layout>
  );
};

class APPClassLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }

  }

  componentWillMount() {
    const { negocio } = Storage.getState()
    if (localStorage.getItem('token')) {
      const urlSession = Servidor.host + Servidor.puertoApp + '/api/v1/session'
      Axios.get(urlSession, { headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')), identificadorNegocio: negocio } })
        .then(response => {
          if (response.status === Servidor.responseOK) {
            Storage.dispatch({
              type: "OBTENCION_CREDENCIALES",
              usuario: response.data.data,
              obtencionInfoUsuario: true
            })
            this.obtencionDatosUsuarios(response.data.data)
          } else {
            this.obtencionDatosUsuarios(Storage.getState().usuario)
          }
        })
    }
  }

  obtencionDatosUsuarios = (usu) => {
    //Obtencion de nodulos
    var tipoDominio = "T"
    var tipoNegocio = "L"
    if (usu.dominio != null) {
      if (usu.dominio.atributos != null) {
        tipoDominio = usu.dominio.atributos.tipo_unidad
      }
    }


    Storage.dispatch({
      type: "OBTENCION_AMBIENTES",
      tipoDominio,
      tipoNegocio
    })
    var hijos = ''
    var padres = ''
    Axios.get(Servidor.host + Servidor.puertoCentury + '/api/empresa/node/' + usu.empresas_id + '/1', { headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) } })
      .then(response => {
        if (response.status === 200) {
          response.data.data.forEach((item, key) => {
            if (key === 0) {
              padres = item.toString()
            } else {
              padres += ',' + item.toString()
            }
          })
          Storage.dispatch({
            type: "OBTENCION_JERARQUIA_PADRES",
            empresasPadres: padres
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      })
    console.log()
    //Obtencion de empresas hijas
    switch (usu.tipo) {
      case 1:
      case 4:
      case 5:
        Axios.get(Servidor.host + Servidor.puertoCentury + '/api/empresa/node/' + usu.empresas_id + '/0', { headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token'))} })
           .then(response => {
             if (response.status === 200) {
               response.data.data.forEach((item, key) => {
                 if (key === 0) {
                   hijos = item.toString()
                 } else {
                   hijos += ',' + item.toString()
                 }
               })
               Storage.dispatch({
                 type: "OBTENCION_JERARQUIA_HIJOS",
                 empresasHijos: hijos,
                 usuarioObtenido: true
               })
             }
           })
           .catch(function (error) {
             console.log(error);
           })
       /* hijos = usu.empresas_id
        Storage.dispatch({
          type: "OBTENCION_JERARQUIA_HIJOS",
          empresasHijos: hijos,
          usuarioObtenido: true
        })*/
        break;
      case 2:
        Axios.get(Servidor.host + Servidor.puertoCentury + '/api/empresa/obtener-hijo/dominio_id/' + usu.empresas_id, { headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) } })
          .then(response => {
            if (response.status === 200) {
              const datos = response.data.hijos
              Storage.dispatch({
                type: "OBTENCION_JERARQUIA_HIJOS",
                empresasHijos: datos,
                usuarioObtenido: true
              })
            }
          })
          .catch(function (error) {
            console.log(error);
          })
        break
      case 3:
        Axios.get(Servidor.host + Servidor.puertoCentury + '/api/empresa/node/' + usu.empresas_id + '/0', { headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) } })
          .then(response => {
            if (response.status === 200) {
              response.data.data.forEach((item, key) => {
                if (key === 0) {
                  hijos = item.toString()
                } else {
                  hijos += ',' + item.toString()
                }
              })
              Storage.dispatch({
                type: "OBTENCION_JERARQUIA_HIJOS",
                empresasHijos: hijos,
                usuarioObtenido: true
              })
            }
          })
          .catch(function (error) {
            console.log(error);
          })
        /* Axios.get(Servidor.host + Servidor.puertoCentury + '/api/empresa/obtener-hijo/negocio_id/' + usu.empresas_id, { headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) } })
           .then(response => {
             if (response.status == 200) {
               const datos = response.data.hijos
               Storage.dispatch({
                 type: "OBTENCION_JERARQUIA_HIJOS",
                 empresasHijos: datos,
                 usuarioObtenido: true
               })
             }
           })
           .catch(function (error) {
             console.log(error);
           })*/
        break;
      default:
        break;
    }



  }

  render() {
    var { usuarioObtenido } = Storage.getState()
    return (
      usuarioObtenido
        ?
        <AppLayout />
        :
        <div style={{ width: '100%' }} >
          <center style={{
            display: 'flex',
            height: '100vh'

          }}>
            <CircularProgress className="mx-auto" style={{ margin: 'auto' }} size={100} />
          </center>
        </div >

    );
  }
}


export default APPClassLayout;
