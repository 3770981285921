import React, { useEffect, useState } from 'react'
import { ISelecctionButtons } from '../../ChartHelpers/Charts.interface'
import ActionsButtons from './ActionsButtons'
const FiltrosOperation = ({ noCanastilla = false, onChange, prevActive }: ISelecctionButtons): JSX.Element => {
    const [active, setActive] = useState(3)
    useEffect(() => {
        if (prevActive)
            setActive(prevActive)
    })
    const buttons = ['CANASTILLA', 'COMBUSTIBLE', 'KIOSCO', 'TODAS']

    const handleOnChange = (operation: number) => {
        setActive(operation)
        onChange(operation)
    }

    return (
        <div className="row mx-auto" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {buttons.map((btn, pos) => noCanastilla && pos === 0 ? null : (
                <ActionsButtons text={btn} action={() => handleOnChange(pos)} active={active === pos} key={pos} />
            ))}
        </div>
    )
}
export default FiltrosOperation
