import React, { useEffect } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
// import cyanTheme from "./themes/cyanTheme";
// import orangeTheme from "./themes/orangeTheme";
// import amberTheme from "./themes/amberTheme";
// import pinkTheme from "./themes/pinkTheme";
// import blueTheme from "./themes/blueTheme";
// import purpleTheme from "./themes/purpleTheme";
// import greenTheme from "./themes/greenTheme";
import AppLocale from "../lngProvider";
import servidor from "util/Server"
import Axios from 'axios'
import Storage from 'util/store/Storage'
import moment from 'moment'
// import {
//   AMBER,
//   BLUE,
//   CYAN,
//   DARK_AMBER,
//   DARK_BLUE,
//   DARK_CYAN,
//   DARK_DEEP_ORANGE,
//   DARK_DEEP_PURPLE,
//   DARK_GREEN,
//   DARK_INDIGO,
//   DARK_PINK,
//   DEEP_ORANGE,
//   DEEP_PURPLE,
//   GREEN,
//   INDIGO,
//   PINK,

// } from "constants/ThemeColors";
import { getDataDominio } from "./AppServices"
import SignIn from "./SignIn";
import CircularProgress from '@material-ui/core/CircularProgress';
import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";
import { setDarkTheme, setThemeColor } from "../actions/Setting";
import AppLayout from "./AppLayout";
import PageNotFound from "app/routes/extraPages/routes/404"

const App = (props,context) => {
  const dispatch = useDispatch();
  const { darkTheme, locale, isDirectionRTL } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const isDarkTheme = darkTheme;
  const { match, location } = props;

  useEffect(() => {
    window._MUI_USE_NEXT_TYPOGRAPHY_VARIANTS_ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }
    const params = new URLSearchParams(props.location.search);
    if (params.has("theme-name")) {
      dispatch(setThemeColor(params.get('theme-name')));
    }
    if (params.has("dark-theme")) {
      dispatch(setDarkTheme());
    }
  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);


  /*const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme({ ...indigoTheme, direction: 'rtl' });
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default: createMuiTheme(indigoTheme);
    }
    return applyTheme;
  };*/

  let applyTheme = createMuiTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createMuiTheme(darkTheme)
  } else {
    // applyTheme = getColorTheme(themeColor, applyTheme);
  }
  if (location.pathname === '/') {
    if (authUser === null) {
      return (<Redirect to={'/app/dashboard/crypto'} />);
    } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      return (<Redirect to={'/app/dashboard/crypto'} />);
    } else {
      return (<Redirect to={initURL} />);
    }
  }
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }
  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <RTL>
            <div className="app-main">
              <Switch>
                {/*<RestrictedRoute path={`${match.url}app`} authUser={authUser}
                                 component={SignIn}/>
                <Route path='/signin' component={SignIn}/>
  <Route path='/signup' component={SignUp}/>*/}
                <Route path={`${match.url}app`} component={props.isAutenticado ? AppLayout : SignIn} />
                <Route path={`${match.url}app/404`}
                  component={PageNotFound} />
                <Route
                  component={props.isAutenticado ? AppLayout : SignIn} />
                
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

class APPClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAutenticado: false,
      usuario: [],
      loader: true
    }
    Storage.subscribe(() => {
      this.setState({
        isAutenticado: Storage.getState().token,
        usuario: Storage.getState().usuario,
      })
    })
  }

  componentWillMount() {
    let dCompleto = window.location.href
    dCompleto = dCompleto.replace("https://", "").replace("http://", "").replace(":3000", "").replace(":3001", "").replace("//","/")
    var dsplit = dCompleto.split("/")
    if (dsplit[1] === "app") {
      dCompleto = dsplit[0]
    } else {
      dCompleto = dsplit[0] + "/" + dsplit[1]
    }
    if (dCompleto.slice(-1) === "/") {
      dCompleto = dCompleto.replace("/", "")
    }
    this.ontenerDominio(dCompleto)
  }

  ontenerDominio = async (dCompleto) => {
    const negocioDomain = await getDataDominio(dCompleto)
    try {
      document.title = negocioDomain.data.alias
      Storage.dispatch({
        type: "OBTENCION_DOMINIO",
        dominio: negocioDomain.id,
        negocio: negocioDomain.negocio_id,
        infoDominio: negocioDomain.data,
        loaderDominio: false,
        tipo_empresa: negocioDomain.tipo
      })
      Storage.dispatch({
        type: "ACTUALIZACION_DATOS",
        actualizarvista: true,
        loaderDominio: false
      })
      this.obtenerParametros(negocioDomain.data)
      this.validarSession(negocioDomain.negocio_id)
    } catch (error) {
      document.title = "recurso no encontrado"
      Storage.dispatch({
        type: "OBTENCION_DOMINIO",
        dominio: 0,
        negocio: 0,
        infoDominio: {},
        loaderDominio: false,
        tipo_empresa: 0
      })
      Storage.dispatch({
        type: "ACTUALIZACION_DATOS",
        actualizarvista: true,
        loaderDominio: false
      })
      this.validarSession(0)
      
    }

  }

  obtenerParametros = (params) => {
    if (params) {
      if (params.parametros) {
        Storage.dispatch({
          type: "OBTENCION_COLORES_DOMINIO",
          color1: params.dominio.parametros.color1,
          color2: params.dominio.parametros.color2
        })
      }

    }
  }

  validarSession = (domi) => {
    if (localStorage.getItem('token') || domi === 0) {
      var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
      var url = servidor.host + servidor.puertoApp;
      Axios.get(url + "/api/v1/", { headers: { Authorization: ntoken, identificadorNegocio: domi } })
        .then(response => {
          this.setState({ loader: false })
          if (response.status === 205) {
            Storage.dispatch({
              type: "VALIDAR_SESION",
              token: false,
              tiempo: ''
            })
            localStorage.removeItem("token");
            //setTimeout(()=>this.validarSession(), 2000);
          } else if (response.status === 206) {
            Storage.dispatch({
              type: "VALIDAR_SESION",
              token: false,
              tiempo: ''
            })
          } else if (response.status === 200) {
            Storage.dispatch({
              type: "VALIDAR_SESION",
              token: true,
              tiempo: moment(response.data.data.fechavencimiento).format('hh:mm:ss a')
            })
          }
        })
        .catch(() => {
          Storage.dispatch({
            type: "VALIDAR_SESION",
            token: false,
            tiempo: ''
          })
          this.setState({ loader: false })
        })
    } else {
      Storage.dispatch({
        type: "VALIDAR_SESION",
        token: false
      })
      this.setState({ loader: false })
    }
  }





  render() {
    const { location, history, match } = this.props
    const { isAutenticado, loader } = this.state
    return (
      loader
        ?
        <div style={{ width: '100%' }}>
          <center style={{
            display: 'flex',
            height: '100vh'

          }}>
            <CircularProgress className="mx-auto" style={{ margin: 'auto' }} size={100} />
          </center>
        </div>
        :
        <App
          location={location}
          history={history}
          match={match}
          isAutenticado={isAutenticado}
        />
    );
  }
}


export default APPClass;
