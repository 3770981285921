import React from "react";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import LineIndicator from "components/dashboard/Intranet/LineIndicator";
import { setearMiles } from "../../../util/funciones";
import Server from 'util/Server'
import Storage from 'util/store/Storage'
const ProjectsCell = ({ data }) => {
  const {
    id,
    promotor,
    hora,
    num_ventas,
    sobres,
    ventas,
    porcentaje,
  } = data;
  let registro = Date.now();
  const {dominio} = Storage.getState()
  return (
    <tr tabIndex={-1} key={id}>
      <td className="max-width-100">
        <p className="text-truncate mb-0">{promotor}</p>
      </td>

      <td className="text-nowrap">{hora}</td>
      <td>
        <LineIndicator
          width={porcentaje / 4 + "%"}
          color={porcentaje <= 25 ? "red" : porcentaje > 25 && porcentaje <= 50 ? "yellow" : porcentaje > 50 && porcentaje <= 75 ? "blue" : "green"}
          value={porcentaje}
        />
        {num_ventas}
      </td>
      <td>$ {ventas ? setearMiles(ventas) : 0} </td>
      <td> {sobres} </td>
      {<td>
        <ul className="team-list">
          <li>
            <Avatar
            className="user-avatar"
            src={
              Server.hostMultimedia +
              "/" +
              dominio +
              "/persona/" +
              id +
              ".png?v=" +
              registro
            }
            />
          </li>
        </ul>
      </td>}
    </tr>
  );
};

export default ProjectsCell;
