import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import NewDashboard from "../vistas/Dashboard/NewDashboard"
import Inicio from "../vistas/Dashboard"
import Empresas from "../vistas/Empresas"
import Usuarios from "../vistas/Usuarios"
import Configuracion from "../vistas/Configuracion"
import Fidelizacion from '../vistas/Fidelizacion';
import Movimientos from '../vistas/Acciones';
import Rerportes from '../vistas/Reportes'
import Eds from '../vistas/Eds'
import Servicios from "../vistas/Servicios"
import { esDominioTerpel } from "util/utils";
import Storage from 'util/store/Storage'
let Str = ""
let dCompleto = window.location.href
dCompleto = dCompleto.replace("https://", "").replace("http://", "").replace(":3000", "").replace("//", "/")
const dsplit = dCompleto.split("/")
if (dsplit[1] === "app") {
  Str = "/app"
} else {
  if (dsplit.length > 1 && dsplit[1] !== "app" && dsplit[1] !== "") {
    Str = "/" + dsplit[1] + "/app"
  } else {
    Str = "/app"
  }
}
if (dCompleto.slice(-1) === "/") {
  dCompleto = dCompleto.replace("/", "")
}
const { usuario } = Storage.getState()
const Routes = () => {
  return (
    <Switch>
      <Route path={`${Str}/dashboard`} component={esDominioTerpel ? NewDashboard : Inicio} />
      <Route path={`${Str}/configuracion`} component={Configuracion} />
      <Route path={`${Str}/empresa`} component={Empresas} />
      <Route path={`${Str}/usuarios`} component={Usuarios} />
      <Route path={`${Str}/fidelizacion`} component={Fidelizacion} />
      <Route path={`${Str}/acciones`} component={Movimientos} />
      <Route path={`${Str}/eds`} component={Eds} />
      <Route path={`${Str}/reportes`} component={Rerportes} />
      <Route path={`${Str}/servicios`} component={Servicios} />
      <Route component={esDominioTerpel(usuario.dominio) ? NewDashboard : Inicio} />
    </Switch>
  )
}



export default withRouter(Routes);
