//Funcion para setear el precio
import Axios from "axios";
import server from "util/Server";
import swal from 'sweetalert';
import Storage from "util/store/Storage";
import moment from 'moment';
export function setearMiles(numero) {
  const { usuario } = Storage.getState()
  let moneda = "COP";
  try {
    moneda = usuario.dominio.parametros.flag
  } catch (error) {

  }
  switch (moneda) {
    case "COP":
      return new Intl.NumberFormat("de-DE").format(
        parseFloat(numero).toFixed(2)
      );
    case "mx":
      return new Intl.NumberFormat("es-MX").format(
        parseFloat(numero).toFixed(2)
      );
    case "us":
      return new Intl.NumberFormat("en-IN").format(
        parseFloat(numero).toFixed(2)
      );
    default:
      return new Intl.NumberFormat("de-DE").format(
        parseFloat(numero).toFixed(2)
      );

  }
}

export function sessionCaducada() {
  var Str = ""
  var dCompleto = window.location.href
  dCompleto = dCompleto.replace("https://", "").replace("http://", "").replace(":3000", "").replace("//", "/")
  var dsplit = dCompleto.split("/")
  if (dsplit[1] === "app") {
    Str = "/"
  } else {
    if (dsplit.length > 1 && dsplit[1] !== "app" && dsplit[1] !== "") {
      Str = "/" + dsplit[1] + "/"
    } else {
      Str = "/"
    }
  }
  swal("ERROR", "¡Su session ha caducado, por favor ingrese nuevamente!", "warning");
  setTimeout(function () { window.location.href = Str; }, 3000)
}

export function obtenerValoresArray(array) {
  console.log(array)
  let valores = array.map((valor) => valor.value).join(",")
  return valores
}

export function replaceAll(inputString, stringToReplace, stringThatReplace) {
  while (inputString.indexOf(stringToReplace) > -1) {
    inputString = inputString.replace(stringToReplace, stringThatReplace)
  }

  return inputString
}

export function obtenerImpuestoTotal(
  cantidad,
  precio,
  impuestos
) {
  let impuestoTotal = 0;
  if (!impuestos) {
    return impuestoTotal
  }
  impuestos.forEach((impu) => {
    const precioTotal = cantidad * precio;
    if (impu.porcentaje_valor === "%") {
      impuestoTotal += (parseFloat(precioTotal) * parseFloat(impu.impuesto_valor)) / 100;
    }
  });
  impuestos.forEach((impu) => {
    if (impu.porcentaje_valor === "$") {
      impuestoTotal += cantidad * parseFloat(impu.impuesto_valor);
    }
  });
  return parseFloat(impuestoTotal);
}

export function setearImpuesto(
  unidades_contenido_valor,
  costo_producto,
  impuestos,
  cantidad
) {
  let imp = 0;
  impuestos.forEach((impu) => {
    var coston = 0;
    // coston = unidades_contenido_valor * cantidad;
    coston = cantidad;
    coston = coston * costo_producto;
    if (impu.porcentaje_valor === "%") {
      imp += (parseFloat(coston) * parseFloat(impu.impuesto_valor)) / 100;
    }
    if (impu.porcentaje_valor === "$") {
      imp +=
        parseFloat(cantidad) *
        parseFloat(unidades_contenido_valor) *
        parseFloat(impu.impuesto_valor);
    }
  });
  // impuestos.forEach((impu) => {
  //   let coston = 0;
  //   coston = unidades_contenido_valor * cantidad;
  //   coston = coston * costo_producto;
  //   if (impu.porcentaje_valor === "$") {
  //     imp +=
  //       parseFloat(cantidad) *
  //       parseFloat(unidades_contenido_valor) *
  //       parseFloat(impu.impuesto_valor);
  //   }
  // });
  return imp;
}
export function calcularPorcentaje(datosArr) {

  let total = 0;
  let porcentaje = [];
  datosArr.forEach((dat) => {
    total += parseInt(dat);
  });

  datosArr.forEach((dat) => {
    porcentaje.push((parseInt(dat) * 100) / total);
  });

  return porcentaje;
}

export function permisosModulos(props) {
  let asignaciones = ""
  try {
    const enlace = props.match.url

    let partes = enlace.split("/")
    let enlaceFinal = ""
    if (partes.length === 5) {
      enlaceFinal = "/" + partes[2] + "/" + partes[3] + "/" + partes[4]
    } else if (partes.length === 4) {
      enlaceFinal = "/" + partes[1] + "/" + partes[2] + "/" + partes[3]
    } else {
      enlaceFinal = "/" + partes[0] + "/" + partes[1] + "/" + partes[2]
    }
    const { usuario } = Storage.getState();
    if (Array.isArray(usuario.modulos)) {
      usuario.modulos.forEach((modulo) => {
        if (modulo.link === enlaceFinal) {
          asignaciones = modulo.asignaciones
        }
      });
    }
  } catch (error) {

  }
  return asignaciones;
}

export const esUsuarioFinanciera = (tipo, atributos) => {
  try {
    switch (tipo) {
      case 3:
        return atributos.tipo_negocio === "F"
      default:
        return atributos.tipo_unidad === "F"
    }
  } catch (error) { return false }
}


export const valorParametro = (parametro, empresa) => {
  try {
    return empresa.parametros[parametro] || 'N'
  } catch (error) {
    return 'N'
  }

}

export const createPDF = (nombre, html) => {

  const { usuario, dominio } = Storage.getState();
  const headers = {
    Authorization: server.obtenerToken(),
    versionapp: server.versionApp,
    versioncode: server.versionCode,
    fecha: moment().format(),
    aplicacion: server.aplicacion,
    identificadordispositivo: server.aplicacion
  }
  const datos = {
    template: html,
    filename: nombre + ".pdf",
    dominio: dominio,
    entidad: "movimiento",
    empresas_id: parseInt(usuario.empresas_id),
  };
  Axios.post(
    server.host + server.puertoCentury + "/api/pdf/download",
    datos,
    { headers: headers }
  ).then((response) => {

    if (response.status === 201) {
      var link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.download = response.data.data.filename;
      link.href = response.data.data.url;
      link.click();
    } else {
      //swal("Fallo al guardar", "Por favor verifique los datos", "warning");
    }
  });
};




export const getColorCombustible = (tipo_combustible) => {
  let color = ""
  switch (tipo_combustible) {
    case "CORRIENTE":
      color = "#E50000"
      break;
    case "DIESEL":
      color = "#FFE000"
      break;
    case "EXTRA":
      color = "#0013F9"
      break;
    case "GAS":
      color = "#0EC100"
      break;
    case "GLP":
      color = "#939393"
      break;
    case "ADBLUE":
      color = "#3E9AFF"
      break;
    default:
      break;
  }
  return (color)
};

const fechas = {
  DATE_VISTA: "DD/MM/YYYY",
  DATETIME_VISTA: "DD/MM/YYYY hh:mm a",
  timezone: "YYYY-MM-DDTHH:mm:ss",
  sinTimezone: "YYYY-MM-DD HH:mm:ss",
  TIME_VISTA: "hh:mm a"
}


export const estilizar_objeto = (objeto) => {
  const objeto_Estilizado = JSON.parse(JSON.stringify(objeto, function (key, value) {
    if (key.includes("_peso")) {
      return '$ ' + setearMiles(value)
    } else if (key.includes("_formateado")) {
      return setearMiles(value)
    } else if (key.includes("_datetime")) {
      return moment(value, resolveTimeZone(value)).format(fechas.DATETIME_VISTA)
    } else if (key.includes("_time")) {
      return moment(value).format(fechas.TIME_VISTA)
    } else if (key.includes("_date")) {
      return moment(value).format(fechas.DATE_VISTA)
    } else {
      return value
    }
  }))
  return (objeto_Estilizado)
};

const resolveTimeZone = (fecha) => {
  if (fecha === null) return 'N/A'
  if (fecha.includes("T")) {
    return fechas.timezone
  } else {
    return fechas.sinTimezone
  }
}
