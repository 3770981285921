import { columnsDatatable, columnsPDF } from 'components/Datatable'
import { DOMINIOS } from 'constants/centuryConstans'
import XLSX from 'xlsx'
export const LeerExcel = async (archivo: any): Promise<any> => {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      var data = e.target.result
      let readedData = XLSX.read(data, { type: 'binary' })
      const wsname = readedData.SheetNames[0]
      const ws = readedData.Sheets[wsname]

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 })
      resolve(dataParse)
    }
    reader.readAsBinaryString(archivo)
  })
}

export const objetoVacio = (objeto: object | null): boolean => {
  if (objeto === null || Object.entries(objeto).length === 0) {
    return true
  }
  return false
}

export const numeroValido = (numero: number | string): boolean => {
  if (
    (numero + '').trim().length > 0 &&
    validarExpresionRegular(numero + '', /^[0-9]+([.][0-9]+)?$/) &&
    !isNaN(parseFloat(numero + '')) &&
    parseFloat(numero + '') > 0
  ) {
    return true
  }
  return false
}

export const esNumero = (numero: number | string): boolean => {
  if (validarExpresionRegular(numero + '', /^[0-9]+([.][0-9]+)?$/)) {
    return true
  }
  return false
}

export const validarExpresionRegular = (str: string, expresionRegular: RegExp): boolean => {
  return expresionRegular.test(str)
}

export const transformDataTableToPdfModel = (columnas: columnsDatatable[]): columnsPDF[] => {
  return columnas.map(columna => ({
    header: columna.header,
    dataKey: columna.field
  }))
}

export const textoCentradoPDF = (doc: any, text: string, alto: number): any => {
  const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
  const textOffset = (doc.internal.pageSize.width - textWidth) / 2
  return doc.text(textOffset, alto, text)
}

export const EtiquetaBajoTablaPDF = (doc: any): number => {
  return doc.lastAutoTable.finalY + 15
}

export const snakeCaseToSeparatedUppercase = (element: any): any => {
  if (Array.isArray(element)) {
    return element.map(snakeCaseToSeparatedUppercase)
  } else if (typeof element === 'object' && element !== null) {
    const cloneObject: { [key: string]: string } = {}
    const keys = Object.keys(element)
    keys.forEach((key: string) => {
      const separatedKey = key.toUpperCase().replace(/_/g, ' ')
      const value = element[key]
      cloneObject[separatedKey] = snakeCaseToSeparatedUppercase(value)
    })
    Object.setPrototypeOf(cloneObject, Object.getPrototypeOf(element))
    return cloneObject
  }
  return element
}

export const esDominioDatagas = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.DATAGAS
  } catch (error) {
    return false
  }
}
export const esDominioTerpel = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.TERPEL
  } catch (error) {
    return false
  }
}
export const esDominioFuelly = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.FUELLY
  } catch (error) {
    return false
  }
}
export const esDominioFirefuel = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.FIREFUEL
  } catch (error) {
    return false
  }
}

export const Logger = (mensaje: string, descripcion?: string) => {
  console.log('-------------------------------------------')
  console.log(`             ${descripcion}                `)
  console.log(`${mensaje}`)
  console.log('-------------------------------------------')
}

export const LoggerError = (mensaje: string, funcion?: string, archivo?: string) => {
  console.log('-------------------------------------------')
  console.log(`%cError en ${archivo ?? ''}.${funcion ?? ''}`, 'color: red; font-size: 20px')
  console.log(`%c${mensaje}`, 'color:red')
  console.log('-------------------------------------------')
}
