import React from 'react';
import {List} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import "./index.css";

const NavMenuItem = props => {
  const {name, icon, link, color} = props;

  return (
    <List component="div" className='nav-menu-item'>
      <NavLink className="prepend-icon nav-menu-link" to={link}> 
        {/* Display an icon if any */}
        {!!icon && (
          <i className={'zmdi zmdi-hc-fw  zmdi-' + icon}/>
        )}
        <PlayCircleOutlineIcon className="nav-text" style={{fontSize:'10px', marginRight:'3px'}}></PlayCircleOutlineIcon><span className="navmenumod nav-text" style={color ? {color: color}: {color: "white"}}>{/*<IntlMessages id={name}/>*/}{name}</span>
      </NavLink>
    </List>
  )
};

export default NavMenuItem;