import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';
import Storage from 'util/store/Storage'
import Servidor from 'util/Server'
import Axios from 'axios'
import Server from 'util/Server'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';

const UserInfo = () => {
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState(false)

  const handleClick = event => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleCerrarSesion = () => {
    var ntoken = localStorage.getItem('token')
    Axios.delete(Servidor.host + Servidor.puertoApp + "/api/v1/", { headers: { Authorization: ntoken } })
      .then(response => {
      })
      .catch(function (error) {
        console.log(error);
      })
    localStorage.removeItem('token');
    window.location.reload();
  }

  var { usuario,dominio } = Storage.getState()
  var registro = Date.now();
  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
        fullWidth={true}
        disableBackdropClick={false}
        style={{ zIndex: 50000 }}
      >
        <DialogContent style={{ zIndex: 50000 }} >
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="nombre" className="col-form-label">NOMBRE</label>
              <input id="nombre" autoComplete="off" className="form-control" type="text" disabled={true} value={usuario.nombres + " " + usuario.apellidos} />
            </div>
            <div className="col-md-3">
              <label htmlFor="identificacion" className="col-form-label">IDENTIFICACION</label>
              <input id="identificacion" autoComplete="off" className="form-control" type="text" disabled={true} value={usuario.identificacion} />
            </div>
            <div className="col-md-3">
              <label htmlFor="usuario" className="col-form-label">USUARIO</label>
              <input id="usuario" autoComplete="off" className="form-control" type="text" disabled={true} value={usuario.usuario} />
            </div>
          </div>
          {usuario.atributos != null ?
            <div className="row">
              <div className="col-md-3">
                <label htmlFor="tipoSangre" className="col-form-label">TIPO DE SANGRE</label>
                <input id="tipoSangre" autoComplete="off" className="form-control" type="text" disabled={true} value={usuario.atributos.tipo_sangre.label} />
              </div>
              <div className="col-md-3">
                <label htmlFor="pais" className="col-form-label">PAIS</label>
                <input id="pais" autoComplete="off" className="form-control" type="text" disabled={true} value={usuario.atributos.pais.label} />
              </div>
              <div className="col-md-3">
                <label htmlFor="provincia" className="col-form-label">PROVINCIA</label>
                <input id="provincia" autoComplete="off" className="form-control" type="text" disabled={true} value={usuario.atributos.provincia.label} />
              </div>
              <div className="col-md-3">
                <label htmlFor="ciudad" className="col-form-label">CIUDAD</label>
                <input id="ciudad" autoComplete="off" className="form-control" type="text" disabled={true} value={usuario.atributos.ciudad.label} />
              </div>
            </div>
            : null}
        </DialogContent>
      </Dialog>
      <Avatar
        alt='...'
        src={Server.hostMultimedia + "/" + dominio + "/persona/" + usuario.p_id + ".png?v=" + registro}
        onError={(e) => { e.target.onerror = null; e.target.src = Server.hostMultimedia + "/" + dominio + "/persona/default.png" }}
        className="user-avatar "
      />
      <div className="user-detail">
        <h4 className="user-name" onClick={handleClick}>{usuario.usuario} <i
          className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h4>
      </div>
      <Menu className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0
          }
        }}
      >
        <MenuItem onClick={() => setDialog(true)}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.profile" />
        </MenuItem>
        <MenuItem onClick={() => {
          handleCerrarSesion()
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;


