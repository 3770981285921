import React from "react";
import Highcharts from 'highcharts'
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import moment from "moment";
import { setearMiles } from "util/funciones"
window.Highcharts = Highcharts;
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
class TortasView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      torta_mas_ventas: [],
      checkTortas: 0,
      topSeries7DIAS: [],
      isShow: true
    };

  }


  componentWillMount() {
    const { data } = this.props
    this.setState({
      topSeries7DIAS: data
    })

  }

  async componentDidMount() {
    await this.renderizarGrafica(this.state.topSeries7DIAS)
  }


  validarData = (data) => {
    let vacio = true
    if (Array.isArray(data)) {
      if (Array.isArray(data[0].data)) {
        data[0].data.forEach((dt) => {
          if (dt.y > 0) {
            vacio = false
          }
        })
      }
    }
    this.setState({
      isShow: !vacio
    })
  }

  renderizarGrafica = (series) => {
    const { add } = this.props
    Highcharts.chart("ventas-x-producto-surtidor-7-dias-externo", {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [
          moment()
            .subtract(6, "d")
            .format("dddd DD/MM"),
          moment()
            .subtract(5, "d")
            .format("dddd DD/MM"),
          moment()
            .subtract(4, "d")
            .format("dddd DD/MM"),
          moment()
            .subtract(3, "d")
            .format("dddd DD/MM"),
          moment()
            .subtract(2, "d")
            .format("dddd DD/MM"),
          moment()
            .subtract(1, "d")
            .format("dddd DD/MM"),
          moment().format("dddd DD/MM"),
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "VENTA DE COMBUSTIBLE ULTIMOS 7 DIAS",
        },
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.point.category + '</b>: ' + add + setearMiles(this.point.y);
        }
      },
      /*tooltip: {
        headerFormat:
          '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b> $ {point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },*/
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: series,
    });
  }

  render() {
    const { isShow } = this.state
    const { label } = this.props
    return (
      <>
        <div className="row">
          <div className="container noDatos my-4">
            <h2 className="text-center h1 bg-primary">{label}</h2>
          </div>
        </div>
        <div className="row">
          <div
            id="ventas-x-producto-surtidor-7-dias-externo"
            className="col-md-11 mx-auto"
            style={
              isShow
                ? { marginTop: "15px", display: "block" }
                : { display: "none" }
            }
          ></div>
          {isShow ? null : <div className="text-center noDatos">
            <span>NO HAY DATOS</span>{" "}
          </div>}
        </div>
      </>
    );
  }
}

export default TortasView;
