import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
const Loader = (): JSX.Element => {
  return (
    <div style={{ width: '100%' }}>
      <div className="mx-auto w-100 text-center">
        <CircularProgress className="mx-auto" size={100} />
      </div>
    </div>
  )
}

export default Loader
