import Axios from 'axios'
import server from 'util/Server'

export const getDataDominio = async (dCompleto) => {
    return new Promise(function (resolve, reject) {
        const n = server.host + server.puertoApp;
        Axios.post(n + '/api/v1/domain', { "dominio": dCompleto })
            .then(response => {
                if (response.status === 200) {
                    resolve(response.data.data)
                }
            })
            .catch(function (error) {
                resolve(error)
            })
    })
}
