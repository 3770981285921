import { saveState } from './storeState'
import { createStore } from 'redux'


const reducer = (state, action) => {
    if (action.type === "INICIO_DE_SESION") {
        //
        return {
            ...state,
            token: action.token,
            usuario: action.usuario,
            obtencionInfoUsuario:action.obtencionInfoUsuario
        }
    } else if (action.type === "VALIDAR_SESION") {
        return {
            ...state,
            token: action.token,
            tiempo: action.tiempo
        }
    } else if (action.type === "OBTENCION_CREDENCIALES") {
        return {
            ...state,
            usuario: action.usuario,
            obtencionInfoUsuario:action.obtencionInfoUsuario
        }
    } else if (action.type === "CREACION_USUARIO") {
        return {
            ...state,
            creacionUsuario: action.creacionUsuario,
        }
    }else if (action.type === "DIALOGO_FORMULARIO") {
        return {
            ...state,
            dialogFormulario: action.dialogFormulario,
        }
    } else if (action.type === "FORMULARIO_EXTERNO") {
        return {
            ...state,
            formularioExterno: action.formularioExterno,
            metodoFormulario:action.metodoFormulario,
            formularioId:action.formularioId
        }
    } else if (action.type === "FORMULARIO_PARAMETROS_EQUIPO") {
        return {
            ...state,
            mostrarFormularioParam: action.mostrarFormularioParam,
            metodoFormularioParam: action.metodoFormularioParam,
            datosFormularioParam: action.datosFormularioParam
        }
    } else if (action.type === "MOSTRAR_OCULTAR") {
        return {
            ...state,
            mostrar: action.mostrar
        }
    } else if (action.type === "FORMULARIO_DOCUMENTOS") {
        return {
            ...state,
            formularioDocumento: action.formularioDocumento
        }
    } else if (action.type === "ADJUNCION_PROVEEDORES") {
        return {
            ...state,
            adjuncionProveedorees: action.adjuncionProveedorees
        }
    } else if (action.type === "HISTORICO_PRECIOS") {
        return {
            ...state,
            historicoPrecios: action.historicoPrecios
        }
    } else if (action.type === "ADJUNCION_IDENTIFICADOR") {
        return {
            ...state,
            addIdentificador: action.addIdentificador
        }
    } else if (action.type === "ADJUNCION_RECETA") {
        return {
            ...state,
            addReceta: action.addReceta
        }
    } else if (action.type === "REAL_TIME_EDS") {
        return {
            ...state,
            edsRealtime: action.edsRealtime
        }
    } else if (action.type === "ESTRUCTURA_EDS") {
        return {
            ...state,
            estructuraEDS: action.estructuraEDS
        }
    } else if (action.type === "ADJUNCION_EMPRESAS") {
        return {
            ...state,
            adjuncionEmpresas: action.adjuncionEmpresas,
            precio_minimo:action.precio_minimo,
            precio_maximo:action.precio_maximo,
            precio:action.precio
        }
    } else if (action.type === "CREACION_PRODUCTO") {
        return {
            ...state,
            creacionProducto: action.creacionProducto,
        }
    } else if (action.type === "CREACION_BODEGA") {
        return {
            ...state,
            creacionBodega: action.creacionBodega,
        }
    } else if (action.type === "CREACION_IMPUESTO") {
        return {
            ...state,
            creacionImpuesto: action.creacionImpuesto,
        }
    } else if (action.type === "OBTENCION_DOMINIO") {
        return {
            ...state,
            dominio: action.dominio,
            negocio: action.negocio,
            infoDominio: action.infoDominio,
            loaderDominio: action.loaderDominio,
            tipo_empresa: action.tipo_empresa
        }
    }else if (action.type === "CREACION_MEDIOS_PAGOS") {
        return {
            ...state,
            creacionMediosPagos:action.creacionMediosPagos,
        }
    }else if (action.type === "ACTUALIZACION_IDENTIFICADORES_USUARIO") {
        return {
            ...state,
            documentosUsuario:action.documentosUsuario,
            formularioId: action.formularioId
        }
    }else if (action.type === "CREACION_UNIDADES") {
        return {
            ...state,
            creacionUnidades:action.creacionUnidades,
        }
    }else if (action.type === "DIALOGO_LOCALIZACION") {
        return {
            ...state,
            dialogLocalizacion:action.dialogLocalizacion,
        }
    }else if (action.type === "DATOS_SELECCIONADOS") {
        return {
            ...state,
            seleccionados:action.seleccionados,
        }
    }else if (action.type === "USUARIO_BY_PERSONA") {
        return {
            ...state,
            usuarioByPersona:action.usuarioByPersona,
        }
    }else if (action.type === "CONFIGURACION_AFORO") {
        return {
            ...state,
            formAforo:action.formAforo,
            idTanque:action.idTanque,
            atributosTanque:action.atributosTanque,
        }
    }else if (action.type === "OBTENCION_COLORES_DOMINIO") {
        return {
            ...state,
            color1: action.color1,
            color2: action.color2
        }
    }else if (action.type === "OBTENCION_JERARQUIA_PADRES") {
        return {
            ...state,
            empresasPadres: action.empresasPadres
        }

    } else if (action.type === "VECTORES_AUXILIARES") {
        return {
            ...state,
            auxiliar1: action.auxiliar1,
            auxiliar2: action.auxiliar2,
            auxiliar3: action.auxiliar3,
        }
    } else if (action.type === "OBTENCION_JERARQUIA_HIJOS") {
        return {
            ...state,
            empresasHijos: action.empresasHijos,
            usuarioObtenido: action.usuarioObtenido
        }

    } else if (action.type === "OBTENCION_AMBIENTES") {
        return {
            ...state,
            tipoDominio: action.tipoDominio,
            tipoNegocio: action.tipoNegocio
        }

    }  else if (action.type === "ACTUALIZACION_DATOS") {
        return {
            ...state,
            actualizarvista: action.actualizarvista
        }
    } else {

    }
    return state
}
const store = createStore(
    reducer,
    {
        //Auxiliares
        auxiliar1: [],
        auxiliar2: [],
        auxiliar3: [],
        //EMRPESAS
        empresasPadres: '',
        empresasHijos: '',
        //Ventanas de creacion
        dialogFormulario:false,
        creacionUsuario: false,
        creacionProducto: false,
        creacionBodega: false,
        creacionImpuesto: false,
        formularioExterno:false,
        formularioDocumento:false,
        adjuncionProveedorees:false,
        adjuncionEmpresas:false,
        addReceta:false,
        historicoPrecios:false,
        addIdentificador:false,
        usuarioByPersona:false,
        estructuraEDS:false,
        formAforo:false,
        edsRealtime:false,
        idTanque:0,
        atributosTanque:{},
        precio_minimo:0,
        precio_maximo:0,
        precio:0,
        metodoFormulario:"",
        formularioId:0,
        dialogLocalizacion:false,
        mostrarFormularioParam: false,
        metodoFormularioParam: '',
        datosFormularioParam: {},
        mostrar: false,
        //Actualizaciones
        actualizarvista: false,
        token: false,
        usuario: {},
        obtencionInfoUsuario:false,
        tiempo:'',
        creacionUnidades: false,
        creacionMediosPagos: false,
        color:'#f44336',
        //PARAMETRIZACION
        dominio: 0,
        tipo_empresa: 0,
        negocio: 0,
        infoDominio: {},
        color1: "red",
        color2: "#3f51b5",
        loaderDominio: true,
        usuarioObtenido: false,
        documentosUsuario:false,
        seleccionados:null,
        //AMBIENTES
        tipoDominio:"T",
        tipoNegocio:"L",
    }
)

store.subscribe(function () {
    saveState(store.getState())
})

export default store
