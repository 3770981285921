import React from 'react'
const ActionsButtons = ({ text, action, active }: any): JSX.Element => {

    return (
        <button className={"btn btn-sm p-2 " + (active ? 'btn-primary ' : 'btn-outlined-primary')} onClick={action}>
            {text}
        </button>
    )
}
export default ActionsButtons
