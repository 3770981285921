import React from 'react'
const HeaderTitle = ({ title }: any): JSX.Element => {

    return (
        <div className="row">
            <div className="container noDatos my-4">
                <h2 className="text-center h1 bg-primary">{title}</h2>
            </div>
        </div>
    )
}
export default HeaderTitle
