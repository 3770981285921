import React, { useState, useEffect } from 'react'
//panel del filtro
import { getChartsData } from '../ChartHelpers/Charts.services'
import HeaderTitle from './Components/HeaderTitle'
import Highcharts from 'highcharts'
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import { setearMiles } from "util/funciones"
import { getArrayMonth } from '../ChartHelpers/Time.helper';
import { getSeriesVentasCombustible } from '../ChartHelpers/Chart.helper';
import moment from 'moment';
window.Highcharts = Highcharts;
//ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
const DetallesVenta = ({ updateData, identificadorEstacion, identificadorNegocio }: any): JSX.Element => {
    useEffect(() => {
        getData()
    }, [updateData])


    const getData = async (): Promise<void> => {
        const año = moment().format("YYYY");
        const body = {
            fecha_inicial: año + "-01-01 00:00:00",
            fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
            identificadorEstacion,
            identificadorNegocio
        }
        const data = await getChartsData('ventas_combustible_meses_lite', body)
        const MonthsArray = data.map(({ mes }) => (mes))
        const categories = getArrayMonth(MonthsArray)
        const series = getSeriesVentasCombustible(data)
        construirGrafico(categories, series)
    }

    const construirGrafico = (categories: string[], series: any[]): void => {
        Highcharts.chart("ventas-x-producto-surtidor-externo", {
            chart: {
                type: "column",
            },
            title: {
                text: "",
            },
            xAxis: {
                categories: categories,
                crosshair: true,
            },
            yAxis: {
                min: 0,
                title: {
                    text: "VENTA DE COMBUSTIBLE POR MESES",
                },
            }, tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    return '<b>' + this.point.category + '</b>:  $' + setearMiles(this.point.y);
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: series,
        });
    }


    return (
        <>
            <HeaderTitle title={'VENTAS DE COMBUSTIBLE DEL AÑO'} />
            <div
                id="ventas-x-producto-surtidor-externo"
                className="col-md-11 mx-auto"
            /*style={
                isShow
                    ? { marginTop: "15px", display: "block" }
                    : { display: "none" }
            }*/
            ></div>
        </>
    )
}
export default DetallesVenta
